import { BreadCrumb, GalleryGridView } from '../components';

const GalleryPage = () => {
  return (
    <main className='hero-section'>
      <BreadCrumb product='gallery' />
      <GalleryGridView />
    </main>
  );
};

export default GalleryPage;
