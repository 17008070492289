import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
// import i18n for internationalization
import './i18n';
import { ProductsProvider } from './context/products_context';
import { FilterProvider } from './context/filter_context';
import { CartProvider } from './context/cart_context';
import { Auth0Provider } from '@auth0/auth0-react';
import { Loading } from './components';
import {
  auth_dummy4,
  auth_dummy5,
  auth_dummy6,
  auth_redirect_uri,
} from './utils/constants';

const authProviderConfig = {
  domain: auth_dummy4,
  clientId: auth_dummy5,
  ...(auth_dummy6 ? { audience: auth_dummy6 } : null),
  redirectUri: auth_redirect_uri,
  cacheLocation: 'localstorage',
};

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <Auth0Provider {...authProviderConfig}>
      <ProductsProvider>
        <FilterProvider>
          <CartProvider>
            <App />
          </CartProvider>
        </FilterProvider>
      </ProductsProvider>
    </Auth0Provider>
  </Suspense>,
  document.getElementById('root')
);
