export const formatPrice = (number) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(number);
};

export const getUniqueValues = (prefix, data, type) => {
  let unique = data.map((item) => item[type]);
  if (type === 'tags' || type === 'materials' || type === 'style') {
    unique = unique.flat();
  }
  return [prefix, ...new Set(unique)];
};

export const getContextConfig = () => {
  if (process.env.NODE_ENV !== 'production') {
    return {
      dummy10: process.env.REACT_APP_ETSY_ACCESS_KEY,
      dummy20: process.env.REACT_APP_LISTINGS_FR_DEV,
      dummy21: process.env.REACT_APP_LISTINGS_EN_DEV,
      dummy30: process.env.REACT_APP_LISTING_PRODUCT_URL_DEV,
      dummy40: process.env.REACT_APP_AUTH0_DOMAIN,
      dummy50: process.env.REACT_APP_AUTH0_CLIENT_ID,
      dummy60: process.env.REACT_APP_AUTH0_AUDIENCE,
      dummy70: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, // TODO: it seems we don't reference it
      dummy80: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    };
  } else {
    return {
      dummy10: '9nc9cd19s6o9x8gvhwbal4dv', // TODO: move to backend env variable
      dummy20:
        '/api/etsy/shops/rareetsens/listings/active?api_key=9nc9cd19s6o9x8gvhwbal4dv&language=fr&limit=50&fields=listing_id,state,title,original_creation_tsz,price,currency_code,quantity,tags,materials,featured_rank,views,num_favorers,style,language&includes=Images,Section,Translations',
      dummy21:
        '/api/etsy/shops/rareetsens/listings/active?api_key=9nc9cd19s6o9x8gvhwbal4dv&limit=50&fields=listing_id,state,title,original_creation_tsz,price,currency_code,quantity,tags,materials,featured_rank,views,num_favorers,style,language&includes=Images,Section,Translations',
      dummy30: '/api/etsy/listings/',
      dummy40: 'rare-et-sens.eu.auth0.com',
      dummy50: 'jgaAsa1PiUQrbVI8htM9qZnHHqUJNDm4', // TODO: move to backend env variable
      dummy60: process.env.REACT_APP_AUTH0_AUDIENCE,
      dummy70: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, // TODO: it seems we don't reference it
      dummy80: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    };
  }
};
