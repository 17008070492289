import { FaShoppingCart, FaUserMinus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useCartContext } from '../context/cart_context';
import { useProductsContext } from '../context/products_context';
import TranslateButtons from './TranslateButtons';

const CartSignInOutButtons = () => {
  const { t } = useTranslation();
  const { closeSidebar } = useProductsContext();
  const { total_items, clearCart } = useCartContext();
  const { isAuthenticated, logout } = useAuth0();

  return (
    <CartSignInOutButtonsStyle className='cart-btn-wrapper'>
      <Link to='/cart' className='cart-btn' onClick={closeSidebar}>
        {t('cart_label')}
        <span className='cart-container'>
          <FaShoppingCart />
          <span className='cart-value'>{total_items}</span>
        </span>
      </Link>

      {isAuthenticated && (
        <button
          type='button'
          className='auth-btn'
          onClick={() => {
            clearCart();
            localStorage.removeItem('user');
            logout({ returnTo: window.location.origin });
          }}
        >
          <FaUserMinus />
        </button>
      )}
      <TranslateButtons />
    </CartSignInOutButtonsStyle>
  );
};

const CartSignInOutButtonsStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  align-items: center;
  width: 200px;

  .cart-btn {
    color: var(--clr-grey-1);
    font-size: 1.25rem;
    letter-spacing: var(--spacing);
    color: var(--clr-grey-1);
    display: flex;
    align-items: center;
  }
  .cart-container {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 1.25rem;
    svg {
      height: 1.6rem;
      margin-left: 5px;
    }
  }
  .cart-value {
    position: absolute;
    top: -10px;
    right: -16px;
    background: var(--clr-primary-5);
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 0.75rem;
    color: var(--clr-white);
    padding: 12px;
  }
  .auth-btn {
    display: flex;
    align-items: center;
    background: transparent;
    border-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--clr-grey-1);
    letter-spacing: var(--spacing);
    margin-right: 0.5rem;
    svg {
      margin-left: 5px;
    }
  }
`;
export default CartSignInOutButtons;
