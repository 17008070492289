import styled from 'styled-components';
import {
  BreadCrumb,
  StripeLogo,
  MastercardLogo,
  PaypalLogo,
  VisaLogo,
} from '../components';
import { useTranslation } from 'react-i18next';

const SalesNoticePage = () => {
  const { t } = useTranslation();

  return (
    <main className='hero-section'>
      <BreadCrumb breadcrumb={t('sales_notice.title')} />
      <SalesNoticePageStyle className='page section section-center'>
        <article>
          <div className='title'>
            <h3>{t('sales_notice.subtitle')}</h3>
            <div className='underline'></div>
          </div>
          <p className='condition-align'>
            <b>{t('sales_notice.chunks.payment.memo')}</b>{' '}
            <span>
              <StripeLogo />
            </span>
            <b>{t('sales_notice.chunks.payment.suite_1')}</b>{' '}
            <span>
              <PaypalLogo />
            </span>
          </p>
          <p>
            <b>{t('sales_notice.chunks.returns.memo')}</b>
            <br />
          </p>
          <ul>
            <li>{t('sales_notice.chunks.returns.bullet_1')}</li>
            <li>{t('sales_notice.chunks.returns.bullet_2')}</li>
          </ul>
          <p>
            <b>{t('sales_notice.chunks.exchange.memo')}</b>
          </p>
          <p className='condition'>
            {t('sales_notice.chunks.exchange.bullet_1')}
          </p>
          <p>
            <b>{t('sales_notice.chunks.canceled.memo')}</b>
          </p>
          <p className='condition'>
            {t('sales_notice.chunks.canceled.bullet_1')}
          </p>
          <p>
            <b>{t('sales_notice.chunks.loss.memo')}</b>
          </p>
          <ul>
            <li>{t('sales_notice.chunks.loss.bullet_1')}</li>
            <li>{t('sales_notice.chunks.loss.bullet_2')}</li>
          </ul>
          <p>
            <b>{t('sales_notice.chunks.delivery.memo')}</b>
          </p>
          <ul>
            <li>{t('sales_notice.chunks.delivery.bullet_1')}</li>
            <li>{t('sales_notice.chunks.delivery.bullet_2')}</li>
          </ul>
          <p className='condition'>
            {t('sales_notice.chunks.delivery.condition_1')}
            <b>{t('sales_notice.chunks.delivery.condition_2')}</b>
            {t('sales_notice.chunks.delivery.condition_3')}
            <b>{t('sales_notice.chunks.delivery.condition_4')}</b>
            {t('sales_notice.chunks.delivery.condition_5')}
          </p>
          <p>
            <b>{t('sales_notice.chunks.update.memo')}</b>
          </p>
          <p className='condition'>
            {t('sales_notice.chunks.update.bullet_1')}
          </p>
          <p>
            <b>{t('sales_notice.chunks.contact.memo')}</b>
          </p>
          <p className='condition'>
            {t('sales_notice.chunks.contact.bullet_1')}
          </p>
        </article>
      </SalesNoticePageStyle>
    </main>
  );
};

const SalesNoticePageStyle = styled.section`
  display: grid;
  justify-content: start;
  gap: 4rem;
  p {
    line-height: 2;
    margin-top: 1rem;
    margin-bottom: 0;
    color: var(--clr-grey-5);
  }
  .credit-card {
    display: inline-block;
    width: 40px;
    height: 32px;
    border: 1px solid #e2e2e2;
    background: #fdfdfc;
    border-radius: 3px;
    text-align: center;
    margin: 0 0.25rem;
    box-shadow: 0 1px 0 rgba(226, 226, 226, 0.2);
    vertical-align: middle;
  }
  .title {
    text-align: left;
  }
  .underline {
    margin-left: 0;
  }
  .condition {
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 0;
    color: black;
  }
  .condition-align {
    display: flex;
    align-items: center;
  }
  ul {
    list-style-type: disc;
    margin: 0 1rem;
  }
  @media (min-width: 992px) {
    grid-template-columns: auto;
  }
`;
export default SalesNoticePage;
