import { useProductsContext } from '../context/products_context';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Error from './Error';
import Loading from './Loading';
import FeaturedProduct from './FeaturedProduct';

const FeaturedProducts = () => {
  const { t } = useTranslation();
  const {
    products_loading: loading,
    products_error: error,
    featured_products: featured,
    langage,
    error_message,
  } = useProductsContext();
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error message={error_message} />;
  }
  return (
    <FeaturedProductsStyle className="section">
      <div className="title">
        <h2>{t('home_page.featured.title')}</h2>
        <div className="underline"></div>
      </div>
      <div className="section-center featured">
        {featured.slice(0, 3).map((product) => {
          return (
            <FeaturedProduct
              key={product.listing_id}
              {...{ product, langage }}
            />
          );
        })}
      </div>
      <Link to="/products" className="btn">
        {t('nav_shop_label')}
      </Link>
    </FeaturedProductsStyle>
  );
};

const FeaturedProductsStyle = styled.section`
  background: var(--clr-grey-10);
  .featured {
    margin: 4rem auto;
    display: grid;
    gap: 2.5rem;
    img {
      height: 250px;
    }
  }
  .btn {
    display: block;
    width: 148px;
    margin: 0 auto;
    text-align: center;
  }
  @media (min-width: 576px) {
    .featured {
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    }
  }
`;

export default FeaturedProducts;
