import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CanceledPage = () => {
  const { t } = useTranslation();
  return (
    <CanceledPageStyle className='page-100'>
      <div className='sr-content'>
        <div className='pasha-image-stack'>
          <img
            alt=''
            src='https://picsum.photos/280/320?random=1'
            width='140'
            height='160'
          />
          <img
            alt=''
            src='https://picsum.photos/280/320?random=2'
            width='140'
            height='160'
          />
          <img
            alt=''
            src='https://picsum.photos/280/320?random=3'
            width='140'
            height='160'
          />
          <img
            alt=''
            src='https://picsum.photos/280/320?random=4'
            width='140'
            height='160'
          />
        </div>
      </div>
      <section>
        <h3>{t('canceled_page.status_message')}</h3>
        <h4>{t('canceled_page.retry_message')}</h4>
        <Link to='/cart' className='btn'>
          {t('canceled_page.redirect_link')}
        </Link>
      </section>
    </CanceledPageStyle>
  );
};

const CanceledPageStyle = styled.main`
  background: var(--clr-primary-10);
  display: grid;
  place-items: center;
  text-align: center;
  h3,
  h4 {
    text-transform: none;
    margin-bottom: 2rem;
  }
  .sr-content {
    display: flex;
    justify-content: center;
    height: 100%;
    align-self: center;
  }
  .pasha-image-stack {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: auto auto;
  }
  .pasha-image-stack img {
    border-radius: var(--radius);
    background-color: var(--gray-border);
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
    transition: all 0.8s ease;
    opacity: 0;
  }
  .pasha-image-stack img:nth-child(1) {
    transform: translate(30px, 15px);
    opacity: 1;
  }
  .pasha-image-stack img:nth-child(2) {
    transform: translate(-28px, 0px);
    opacity: 1;
  }
  .pasha-image-stack img:nth-child(3) {
    transform: translate(64px, -50px);
    opacity: 1;
  }
  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default CanceledPage;
