import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navbar, Sidebar, Footer, ScrollToTop } from './components';
import {
  Home,
  About,
  Gallery,
  Products,
  SingleProduct,
  Cart,
  Checkout,
  Error,
  AuthWrapper,
  SuccessPage,
  CanceledPage,
  SalesNoticePage,
} from './pages';

function App() {
  return (
    <AuthWrapper>
      <BrowserRouter>
        <Navbar />
        <Sidebar />
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/products' element={<Products />} />
          <Route path='/products/:id' element={<SingleProduct />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/success' element={<SuccessPage />} />
          <Route path='/canceled' element={<CanceledPage />} />
          <Route path='/notice' element={<SalesNoticePage />} />
          <Route path='*' element={<Error />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </AuthWrapper>
  );
}

export default App;
