import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const AmountButtons = ({ quantity }) => {
  const { t } = useTranslation();
  return (
    <AmountButtonsStyle className='amount-btsn'>
      <h4 className='quantity'>
        {t('single_product_page.quantity_label')}
        {quantity}
      </h4>
    </AmountButtonsStyle>
  );
};

const AmountButtonsStyle = styled.div`
  h2 {
    margin-bottom: 0;
  }
  button {
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    padding: 1rem 0;
    width: 2rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h2 {
    margin-bottom: 0;
  }
  .quantity {
    color: var(--clr-primary-5);
  }
`;

export default AmountButtons;
