import { getContextConfig } from '../utils/helpers';
import { GiCompass, GiDiamondHard } from 'react-icons/gi';
import { FaInstagram, FaPinterest } from 'react-icons/fa';

const {
  dummy10,
  dummy20,
  dummy21,
  dummy30,
  dummy40,
  dummy50,
  dummy60,
  dummy70, // TODO: it seems we don't reference it
  dummy80,
} = getContextConfig();

export const links = [
  {
    id: 1,
    text: 'nav_home_label',
    url: '/',
  },
  {
    id: 2,
    text: 'nav_gallery_label',
    url: '/gallery',
  },
  {
    id: 3,
    text: 'nav_shop_label',
    url: '/products',
  },
  {
    id: 4,
    text: 'nav_about_label',
    url: '/about',
  },
];
export const social = [
  {
    id: 1,
    url: 'https://www.instagram.com/rareetsens',
    icon: <FaInstagram />,
  },
  {
    id: 2,
    url: 'https://www.pinterest.fr/search/pins/?rs=ac&q=rareetsens',
    icon: <FaPinterest />,
  },
];
export const services = [
  {
    id: 1,
    icon: <GiDiamondHard />,
    title: 'home_page.services.service_1.title',
    text: 'home_page.services.service_1.description',
  },
  {
    id: 2,
    icon: <GiCompass />,
    title: 'home_page.services.service_2.title',
    text: 'home_page.services.service_2.description',
  },
];

export const languageList = {
  fr: { nativeName: 'Français' },
  en: { nativeName: 'English' },
  // es: { nativeName: 'Español' },
};

export const etsy_listings_fr_url = dummy20;
export const etsy_listings_en_url = dummy21;
export const etsy_listing_product_base_url = dummy30;
export const etsy_listing_product_fr_url = `?api_key=${dummy10}&language=fr&includes=Images,Section,Translations,ShippingInfo`;
export const etsy_listing_product_en_url = `?api_key=${dummy10}&includes=Images,Section,Translations,ShippingInfo`;
export const etsy_end_product_url = `?api_key=${dummy10}&language=fr&includes=Images,Section,ShippingInfo`;
export const auth_dummy4 = dummy40;
export const auth_dummy5 = dummy50;
export const auth_dummy6 = dummy60;
export const stripe_dummy7 = dummy70; // TODO: it seems we don't reference it
export const auth_redirect_uri = dummy80;
export const netlify_check_env = '/api/serverless/check-backend-service';
export const netlify_create_stripe_checkout =
  '/api/serverless/create-stripe-checkout-session';
export const netlify_get_stripe_checkout_session =
  '/api/serverless/checkout-session?sessionId=';
