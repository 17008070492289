import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BreadCrumb } from '../components';
import atelierImg from '../assets/atelier-1.jpeg';

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <main className='hero-section'>
      <BreadCrumb breadcrumb={t('about_announce.title')} />
      <AboutPageStyle className='page section section-center'>
        <img src={atelierImg} alt='la petite histoire ' />
        <article>
          <div className='title'>
            <h2>{t('about_announce.subtitle')}</h2>
            <div className='underline'></div>
          </div>
          <p>
            {t('about_announce.memo')}
            <b>{t('about_announce.extra_1')}</b>
            ...
          </p>
        </article>
      </AboutPageStyle>
    </main>
  );
};

const AboutPageStyle = styled.section`
  display: grid;
  gap: 4rem;
  img {
    width: 100%;
    display: block;
    border-radius: var(--radius);
    height: 500px;
    object-fit: cover;
  }
  p {
    line-height: 2;
    max-width: 45em;
    margin: 0 auto;
    margin-top: 2rem;
    color: var(--clr-grey-5);
  }
  .title {
    text-align: left;
  }
  .underline {
    margin-left: 0;
  }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;
export default AboutPage;
