import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { GiFrance, GiWorld } from 'react-icons/gi';
import { BsCreditCard } from 'react-icons/bs';
import { FaCcPaypal } from 'react-icons/fa';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useCartContext } from '../context/cart_context';
import { formatPrice } from '../utils/helpers';

const CartTotals = () => {
  const { t } = useTranslation();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const {
    isLocalShipping,
    isStripePayment,
    total_amount,
    total_shipping_fee,
    setDomesticShipping,
    setInternationalShipping,
    setStripePayment,
    setPaypalPayment,
  } = useCartContext();
  return (
    <CartTotalsStyle>
      <div>
        <article>
          <h5>
            {t('cart_page.cart_total.shipping_subtotal')}
            <span>{formatPrice(total_amount)}</span>
          </h5>
          <div className='shipping-container'>
            <p>{t('cart_page.cart_total.shipping_label')}</p>
            <div className='btn-container'>
              <button
                type='button'
                className={`${isLocalShipping === 'yes' ? 'active' : null}`}
                onClick={setDomesticShipping}
              >
                <GiFrance />
              </button>
              <button
                type='button'
                className={`${isLocalShipping === 'no' ? 'active' : null}`}
                onClick={setInternationalShipping}
              >
                <GiWorld />
              </button>
            </div>
          </div>
          <p>
            {t('cart_page.cart_total.shipping_fees')}
            <span>{formatPrice(total_shipping_fee)}</span>
          </p>
          <hr />
          <h4>
            {t('cart_page.cart_total.shipping_total')}
            <span>{formatPrice(total_amount + total_shipping_fee)}</span>
          </h4>
          <hr />
          <div className='payment-container'>
            <p>{t('cart_page.cart_total.payment_method')}</p>
            <div className='btn-payment'>
              <button
                type='button'
                className={`${isStripePayment === 'yes' ? 'active' : null}`}
                onClick={setStripePayment}
              >
                <BsCreditCard />
              </button>
              <button
                type='button'
                className={`${isStripePayment === 'no' ? 'active' : null}`}
                onClick={setPaypalPayment}
              >
                <FaCcPaypal />
              </button>
            </div>
          </div>
        </article>
        {isAuthenticated ? (
          <Link to='/checkout' className='btn'>
            {t('cart_page.purchase_link')}
          </Link>
        ) : (
          <button type='button' className='btn' onClick={loginWithRedirect}>
            {t('cart_page.connect_link')}
          </button>
        )}
      </div>
    </CartTotalsStyle>
  );
};

const CartTotalsStyle = styled.section`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  article {
    border: 1px solid var(--clr-grey-8);
    border-radius: var(--radius);
    padding: 1.5rem 3rem;
  }
  h4 {
    text-transform: none;
  }
  h4,
  h5,
  p {
    display: grid;
    grid-template-columns: 200px 1fr;
  }
  p {
    text-transform: none;
  }
  p span {
    margin-left: 25px;
  }
  h4 {
    margin-top: 2rem;
  }
  .shipping-container {
    display: grid;
    grid-template-columns: 200px 1fr;
  }
  .btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* column-gap: 0.5rem; */
    button {
      background: transparent;
      border: 1px solid var(--clr-black);
      color: var(--clr-black);
      width: 1.5rem;
      border-radius: var(--radius);
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        font-size: 1rem;
      }
    }
    .active {
      background: var(--clr-black);
      color: var(--clr-white);
    }
  }
  .payment-container {
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: center;
    p {
      margin-top: 1.5rem;
    }
  }
  .btn-payment {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* column-gap: 0.5rem; */
    button {
      background: transparent;
      border: 1px solid var(--clr-black);
      color: var(--clr-black);
      width: 2.25rem;
      border-radius: var(--radius);
      height: 2.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        font-size: 1.75rem;
      }
    }
    .active {
      background: var(--clr-black);
      color: var(--clr-white);
    }
  }
  .form-control {
    margin-bottom: 1.25rem;
    h5 {
      margin-bottom: 0.5rem;
    }
  }
  @media (min-width: 776px) {
    justify-content: flex-end;
  }
  .btn {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    font-weight: 700;
  }
`;

export default CartTotals;
