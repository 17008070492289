import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import heroImg from '../assets/hero-background.jpeg';
import heroImg2 from '../assets/hero-img-3.jpeg';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <HeroStyle className='section-center'>
      <article className='content'>
        <h1>
          {t('home_page.hero.welcome')}
          <br />
        </h1>
        <img
          src={heroImg2}
          alt={t('home_page.hero.alt_img1')}
          className='img-container-small'
        />
        <p>{t('home_page.hero.announce')}</p>
        <Link to='/gallery' className='btn hero-btn'>
          {t('nav_gallery_label')}
        </Link>
      </article>
      <article className='img-container'>
        <img
          src={heroImg}
          alt={t('home_page.hero.alt_img2')}
          className='main-img'
        />
        <img
          src={heroImg2}
          alt={t('home_page.hero.alt_img1')}
          className='accent-img'
        />
      </article>
    </HeroStyle>
  );
};

const HeroStyle = styled.section`
  min-height: 60vh;
  display: grid;
  place-items: center;
  // h2 {
  //   text-transform: none;
  // }
  .img-container {
    display: none;
  }

  .img-container-small {
    display: block;
    position: relative;
    width: 250px;
    border-radius: var(--radius);
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  p {
    line-height: 2;
    max-width: 45em;
    margin-bottom: 2rem;
    color: var(--clr-grey-5);
    font-size: 1rem;
  }
  .hero-btn {
    margin-bottom: 1rem;
  }

  @media (min-width: 992px) {
    height: calc(100vh - 5rem);
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    h1 {
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.25rem;
    }
    .hero-btn {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
    }
    .img-container {
      display: block;
      position: relative;
    }
    .main-img {
      width: 100%;
      height: 550px;
      position: relative;
      border-radius: var(--radius);
      background-color: white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      display: block;
      object-fit: cover;
    }
    .accent-img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 250px;
      transform: translateX(-60%) rotate(10deg) translateY(-5px);
      border-radius: var(--radius);
      background-color: white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .img-container::before {
      content: '';
      position: absolute;
      width: 10%;
      height: 80%;
      background: var(--clr-primary-9);
      bottom: 0%;
      left: -8%;
      border-radius: var(--radius);
    }
    .img-container-small {
      display: none;
    }
  }
`;

export default Hero;
