import { createContext, useEffect, useContext, useReducer } from 'react';
import reducer from '../reducers/cart_reducer';
import { useTranslation } from 'react-i18next';

import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  CLEAR_CART,
  COUNT_CART_TOTALS,
  SET_DOMESTIC_SHIPPING,
  SET_INTERNATIONAL_SHIPPING,
  SET_STRIPE_PAYMENT,
  SET_PAYPAL_PAYMENT,
} from '../actions';

const getLocalStorage = (key) => {
  let setting = localStorage.getItem(key);
  if (setting) {
    if (key === 'cart') return JSON.parse(setting);
    if (key === 'shipping_local') return setting;
    if (key === 'payment_method') return setting;
  } else {
    if (key === 'cart') return [];
    if (key === 'shipping_local') return 'yes';
    if (key === 'payment_method') return 'yes';
  }
};

const initialState = {
  cart: getLocalStorage('cart'),
  isLocalShipping: getLocalStorage('shipping_local'),
  isStripePayment: getLocalStorage('payment_method'),
  total_items: 0,
  total_amount: 0,
  total_shipping_fee: 0,
};

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { i18n } = useTranslation();

  // add to cart
  const addToCart = (id, quantity, product) => {
    dispatch({
      type: ADD_TO_CART,
      payload: {
        id,
        quantity,
        product,
        language: `${i18n.resolvedLanguage}`,
      },
    });
  };
  // remove item
  const removeItem = (id) => {
    dispatch({ type: REMOVE_CART_ITEM, payload: id });
  };

  // clear cart
  const clearCart = () => {
    dispatch({ type: CLEAR_CART });
  };

  const setDomesticShipping = () => {
    dispatch({ type: SET_DOMESTIC_SHIPPING });
  };
  const setInternationalShipping = () => {
    dispatch({ type: SET_INTERNATIONAL_SHIPPING });
  };

  const setStripePayment = () => {
    dispatch({ type: SET_STRIPE_PAYMENT });
  };
  const setPaypalPayment = () => {
    dispatch({ type: SET_PAYPAL_PAYMENT });
  };

  useEffect(() => {
    dispatch({ type: COUNT_CART_TOTALS });
    localStorage.setItem('cart', JSON.stringify(state.cart));
    localStorage.setItem('shipping_local', state.isLocalShipping);
    localStorage.setItem('payment_method', state.isStripePayment);
  }, [state.cart, state.isLocalShipping, state.isStripePayment]);

  return (
    <CartContext.Provider
      value={{
        ...state,
        addToCart,
        removeItem,
        clearCart,
        setDomesticShipping,
        setInternationalShipping,
        setStripePayment,
        setPaypalPayment,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
// make sure use
export const useCartContext = () => {
  return useContext(CartContext);
};
