import styled from 'styled-components';
import { useProductsContext } from '../context/products_context';
import Loading from './Loading';
import Error from './Error';
import PhotoGallery from './PhotoGallery';

const GalleryGridView = () => {
  const {
    products_loading: loading,
    products_error: error,
    products,
    error_message,
  } = useProductsContext();
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error message={error_message} />;
  }
  return (
    <GalleryGridViewStyle>
      <div className="photos-center">
        {products.map((product) => {
          return <PhotoGallery key={product.listing_id} {...product} />;
        })}
      </div>
    </GalleryGridViewStyle>
  );
};

const GalleryGridViewStyle = styled.section`
  padding: 2rem 0;
  .photos-center {
    width: 90vw;
    max-width: var(--max-width);
    margin: 0 auto;
    display: grid;
    gap: 1rem;
  }
  @media screen and (min-width: 576px) {
    .photos-center {
      grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
    }
  }
`;

export default GalleryGridView;
