import { createContext, useEffect, useContext, useReducer } from 'react';
import reducer from '../reducers/filter_reducer';
import { useTranslation } from 'react-i18next';
import {
  LOAD_PRODUCTS,
  SET_GRIDVIEW,
  SET_LISTVIEW,
  UPDATE_SORT_BY,
  SORT_PRODUCTS,
  UPDATE_FILTERS,
  FILTER_PRODUCTS,
  CLEAR_FILTERS,
} from '../actions';
import { useProductsContext } from './products_context';

const initialState = {
  filtered_products: [],
  all_products: [],
  grid_view: true,
  sortBy: 'date-highest',
  filters: {
    searchText: '',
    category: 'All',
    style: 'All',
    tag: 'All',
    material: 'All',
    min_price: 0,
    max_price: 0,
    price: 0,
    promotion: false,
  },
};

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const { products } = useProductsContext();
  const { i18n, t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({ type: LOAD_PRODUCTS, payload: products });
  }, [products]);

  useEffect(() => {
    dispatch({
      type: FILTER_PRODUCTS,
      payload: { language: `${i18n.resolvedLanguage}` },
    });
    dispatch({ type: SORT_PRODUCTS });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, state.sortBy, state.filters]);

  const setGridView = () => {
    dispatch({ type: SET_GRIDVIEW });
  };
  const setListView = () => {
    dispatch({ type: SET_LISTVIEW });
  };

  const updateSortBy = (e) => {
    // example when you need to use multi-sort parameters (use name to differentiate them)
    // const name = e.target.name
    const value = e.target.value;
    dispatch({ type: UPDATE_SORT_BY, payload: value });
  };

  const updateFilters = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'category') {
      e.target.textContent === t('products_page.filters.filter_prefix')
        ? (value = t('products_page.filters.filter_prefix'))
        : (value = e.target.textContent);
    }
    if (name === 'style') {
      if (value === t('products_page.filters.filter_prefix')) value = 'All';
    }
    if (name === 'price') {
      value = Number(value);
    }
    if (name === 'promotion') {
      value = e.target.checked;
    }
    dispatch({ type: UPDATE_FILTERS, payload: { name, value } });
  };

  const clearFilters = () => {
    dispatch({ type: CLEAR_FILTERS });
  };

  return (
    <FilterContext.Provider
      value={{
        ...state,
        setGridView,
        setListView,
        updateSortBy,
        updateFilters,
        clearFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
// make sure use
export const useFilterContext = () => {
  return useContext(FilterContext);
};
