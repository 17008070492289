import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../utils/helpers';
import { Link } from 'react-router-dom';
import he from 'he';

const ListView = ({ products }) => {
  const { i18n } = useTranslation();

  // images, price, listing_id, Translations
  return (
    <ListViewStyle>
      {products.map((product) => {
        const { listing_id, translations, images, price } = product;
        let lnTitle = '';
        const convertedPrice = price.amount / price.divisor;
        const { us, fr } = translations;
        if (i18n.resolvedLanguage === 'en') {
          lnTitle = us.title;
        } else {
          lnTitle = fr.title;
        }

        return (
          <article key={listing_id}>
            <img
              src={images[0].url_570xN}
              alt={he.decode(lnTitle.substring(0, 35))}
            />
            <div>
              <h4>{he.decode(lnTitle.substring(0, 35))}...</h4>
              <h5 className="price">{formatPrice(convertedPrice)}</h5>
              <p>
                {i18n.resolvedLanguage === 'fr' &&
                  he.decode(fr.description.substring(0, 200))}
                {i18n.resolvedLanguage === 'en' &&
                  he.decode(us.description.substring(0, 200))}
                {i18n.resolvedLanguage === 'es' &&
                  he.decode(fr.description.substring(0, 200))}
                ...
              </p>
              <Link to={`/products/${listing_id}`} className="btn">
                Détails
              </Link>
            </div>
          </article>
        );
      })}
    </ListViewStyle>
  );
};

const ListViewStyle = styled.section`
  display: grid;
  row-gap: 3rem;

  img {
    width: 100%;
    display: block;
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: var(--radius);
    margin-bottom: 1rem;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  h4 {
    margin-bottom: 0.5rem;
  }
  .price {
    color: var(--clr-primary-6);
    margin-bottom: 0.75rem;
  }
  p {
    max-width: 25em;
    margin-bottom: 1rem;
    text-align: justify;
  }
  .btn {
    font-size: 0.5rem;
    padding: 0.25rem 0.5rem;
  }
  @media (min-width: 992px) {
    article {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 2rem;
      align-items: center;
    }
    p {
      max-width: 35em;
    }
  }
`;

export default ListView;
