import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useCartContext } from '../../context/cart_context';
import { formatPrice } from '../../utils/helpers';
import {
  netlify_check_env,
  netlify_create_stripe_checkout,
} from '../../utils/constants';

const StripeCheckout = () => {
  const { t, i18n } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const { cart, isLocalShipping, total_amount, total_shipping_fee } =
    useCartContext();
  const [error, setError] = useState(null);
  const { nickname = '' } = user;

  const checkBackendService = async () => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await axios.get(netlify_check_env);
      setError(null);
    } catch (error) {
      const error_message = `${t('checkout_page.error_message')}${
        error.message
      }`;
      setError(error_message);
    }
  };

  const createStripeCheckoutPayment = async () => {
    try {
      // We need an access token for our API to get the
      // Stripe Customer ID from
      const access_token = await getAccessTokenSilently();

      // Call our Netlify function, passing in the access token
      // as a header, and the cart items as the payload
      const response = await axios.post(
        netlify_create_stripe_checkout,
        JSON.stringify({
          cart,
          isLocalShipping,
          total_shipping_fee,
          lng: i18n.resolvedLanguage,
        }),
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      const { data } = response;
      // The response is a checkout session object,
      // which has a `url` attribute which we simply
      // redirect the user to
      window.location.assign(data.url);
      setError(null);
    } catch (error) {
      const error_message = `${t('checkout_page.error_message')}${
        error.message
      }`;
      setError(error_message);
    }
  };

  useEffect(() => {
    checkBackendService();
    setTimeout(() => {
      createStripeCheckoutPayment();
    }, 3000);
    // eslint-disable-next-line
  }, []);

  return (
    <StripeCheckoutStyle>
      <div className='card-element'>
        <article>
          <h3>
            {t('checkout_page.wait')} {nickname}
          </h3>
          <h5>{t('checkout_page.redirect_stripe_message')}</h5>
          <p>
            {t('checkout_page.payment_message')}
            {formatPrice(total_shipping_fee + total_amount)}
          </p>
        </article>
      </div>
      <div className='checkout-loading'></div>
      {error && (
        <div>
          <div className='card-error card-element' role='alert'>
            {error}
          </div>
          <button
            type='button'
            className='btn retry'
            onClick={() => {
              createStripeCheckoutPayment();
            }}
          >
            {' '}
            {t('checkout_page.redirect_error')}
          </button>
        </div>
      )}
    </StripeCheckoutStyle>
  );
};

const StripeCheckoutStyle = styled.section`
  justify-content: center;
  align-items: center;
  text-align: center;
  h3,
  h5 {
    text-transform: none;
  }
  .card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  .card-error {
    color: rgb(105, 115, 134);
    font-size: 0.75rem;
    line-height: 20px;
    margin: 12px 0;
    text-align: center;
  }
  .checkout-loading {
    width: 6rem;
    height: 6rem;
    margin: 0 auto;
    margin-top: 1rem;
    border-radius: 50%;
    border: 4px solid #ccc;
    border-top-color: var(--clr-primary-5);
    animation: spinner 0.6s linear infinite;
  }
  .retry {
    margin-bottom: 12px;
  }
  @media screen and (min-width: 800px) {
    .card-error {
      color: rgb(105, 115, 134);
      font-size: 1rem;
      line-height: 20px;
      margin: 12px 0;
      text-align: center;
    }
  }
`;

export default StripeCheckout;
