import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import he from 'he';
import { useProductsContext } from '../context/products_context';

import { formatPrice } from '../utils/helpers';
import { sections } from '../data/shop_sections';

import {
  Loading,
  Error,
  ProductImages,
  AddToCart,
  Stars,
  BreadCrumb,
} from '../components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SingleProductPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const {
    single_product_loading: loading,
    single_product_error: error,
    single_product: product,
    getStaticSingleProduct,
    error_message,
  } = useProductsContext();

  useEffect(() => {
    getStaticSingleProduct(id);
    // eslint-disable-next-line
  }, [id, i18n.resolvedLanguage]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        navigate('/');
      }, 3000);
    }
    // eslint-disable-next-line
  }, [error]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error message={error_message} />;
  }
  if (!loading && product !== undefined) {
    const {
      price,
      quantity: stock = 0,
      num_favorers,
      views,
      images,
      translations = [],
    } = product;

    let lnTitle = '';
    let category = ' ';
    const { us, fr } = translations;
    const section = sections.find(
      (section) => section.shop_section_id === product.shop_section_id
    );
    if (i18n.resolvedLanguage === 'en' && us !== undefined) {
      lnTitle = us?.title;
      category = section?.title_en;
    } else if (i18n.resolvedLanguage === 'fr' && fr !== undefined) {
      lnTitle = fr?.title;
      category = section?.title_fr;
    }

    return (
      <SingleProductPageStyle>
        <BreadCrumb
          breadcrumb={`/ ${he.decode(lnTitle?.substring(0, 35))}`}
          product="products"
        />
        <div className="section section-center page">
          <Link to="/products" className="btn">
            {t('single_product_page.link')}
          </Link>
          <div className="product-center">
            {images !== undefined && <ProductImages images={images} />}
            <section className="content">
              <h2>{he.decode(lnTitle.substring(0, 35))}</h2>
              <Stars views={views} num_favorers={num_favorers} />
              <div>
                {i18n.resolvedLanguage === 'fr' &&
                  fr !== undefined &&
                  he
                    .decode(fr?.description)
                    .split('\n')
                    .map((str, index) => <p key={index}>{str}</p>)}
                {i18n.resolvedLanguage === 'en' &&
                  us !== undefined &&
                  he
                    .decode(us?.description)
                    .split('\n')
                    .map((str, index) => <p key={index}>{str}</p>)}
                {i18n.resolvedLanguage === 'es' &&
                  fr !== undefined &&
                  he
                    .decode(fr?.description)
                    .split('\n')
                    .map((str, index) => <p key={index}>{str}</p>)}
              </div>
              <h5 className="price">
                {t('single_product_page.price')}{' '}
                {price !== undefined &&
                  formatPrice(price.amount / price.divisor)}
              </h5>
              <p className="info">
                <span>{t('single_product_page.stock_label')}</span>
                {stock > 0
                  ? t('single_product_page.stock_opt1')
                  : t('single_product_page.stock_opt2')}
              </p>
              <p className="info">
                <span>{t('single_product_page.category_label')}</span>
                {he.decode(category)}
              </p>
              <hr />
              {stock > 0 && <AddToCart product={product} />}
            </section>
          </div>
        </div>
      </SingleProductPageStyle>
    );
  }
  return <Error />;
};

const SingleProductPageStyle = styled.main`
  padding-top: 15px;
  h2 {
    text-transform: none;
  }
  .product-center {
    display: grid;
    gap: 4rem;
    margin-top: 2rem;
    align-items: start;
  }
  .price {
    color: var(--clr-primary-5);
  }
  .desc {
    line-height: 2;
    max-width: 45em;
    text-transform: none;
  }
  .info {
    text-transform: capitalize;
    width: 300px;
    display: grid;
    grid-template-columns: 125px 1fr;
    span {
      font-weight: 700;
    }
  }

  @media (min-width: 992px) {
    .product-center {
      grid-template-columns: 1fr 1fr;
      align-items: start;
    }
    .price {
      font-size: 1.25rem;
    }
  }
`;

export default SingleProductPage;
