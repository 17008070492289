import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import { Loading, Error } from '../components';

const AuthWrapper = ({ children }) => {
  const { isLoading, error } = useAuth0();

  if (isLoading) {
    return (
      <WrapperStyle>
        <Loading />
      </WrapperStyle>
    );
  }
  if (error) {
    return (
      <WrapperStyle>
        <Error message={error.message} />;
      </WrapperStyle>
    );
  }
  return <>{children}</>;
};

const WrapperStyle = styled.section`
  min-height: 100vh;
  display: grid;
  place-items: center;
`;

export default AuthWrapper;
