import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import Confetti from 'react-confetti';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useCartContext } from '../context/cart_context';

const urlGetCheckoutSession = '/api/serverless/get-checkout-session?sessionId=';

const SuccessPage = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const { isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();
  const { clearCart } = useCartContext();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [order, setOrder] = useState('');
  const location = useLocation();
  const sessionId = location.search.replace('?session_id=', '');

  const getCheckoutSession = async () => {
    try {
      const { data } = await axios.get(urlGetCheckoutSession + sessionId);
      const { client_reference_id } = data;
      setError(null);
      setSuccess(true);
      setOrder(client_reference_id);
    } catch (error) {
      const error_message = `${t('success_page.error_message')}${
        error.message
      }`;
      setError(error_message);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate('/');
    } else {
      setTimeout(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      }, 100);
      clearCart();
      if (sessionId) {
        getCheckoutSession();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  return (
    <SuccessPageStyle className='page-100'>
      <section>
        <Confetti width={width} height={height} numberOfPieces={450} />
        <h1>{t('success_page.thanks')}</h1>
        <h3>{t('success_page.confirm_message')}</h3>
        {success && (
          <div className='card-error card-element' role='alert'>
            {t('success_page.order_label')}
            {order}
          </div>
        )}
        <button
          type='button'
          className='btn'
          onClick={() => {
            localStorage.removeItem('user');
            logout({ returnTo: window.location.origin });
          }}
        >
          {t('success_page.redirect_link')}
        </button>
        {error && (
          <div className='card-error card-element' role='alert'>
            {error}
          </div>
        )}
      </section>
    </SuccessPageStyle>
  );
};

const SuccessPageStyle = styled.main`
  background: var(--clr-primary-10);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1 {
    font-size: 4rem;
  }
  h3 {
    text-transform: none;
    margin-bottom: 2rem;
  }
  .card-element {
    border-radius: 4px 4px 0 0;
    padding: 4px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  .card-error {
    color: rgb(105, 115, 134);
    font-size: 0.75rem;
    line-height: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: center;
  }
  @media screen and (min-width: 800px) {
    h1 {
      font-size: 8rem;
    }
  }
`;

export default SuccessPage;
