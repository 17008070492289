import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCartContext } from '../context/cart_context';
import AmountButtons from './AmountButtons';

const AddToCart = ({ product }) => {
  const { quantity: stock, listing_id: id } = product;
  const { addToCart } = useCartContext();
  const { t } = useTranslation();

  return (
    <AddToCartStyle>
      <div className='btn-container'>
        <AmountButtons quantity={stock} />
        <Link
          to='/cart'
          className='btn'
          onClick={() => addToCart(id, stock, product)}
        >
          {t('single_product_page.add_to_cart_label')}
        </Link>
      </div>
    </AddToCartStyle>
  );
};

const AddToCartStyle = styled.section`
  margin-top: 2rem;
  .colors {
    display: grid;
    grid-template-columns: 125px 1fr;
    align-items: center;
    margin-bottom: 1rem;
    span {
      text-transform: capitalize;
      font-weight: 700;
    }
    div {
      display: flex;
    }
  }
  .color-btn {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #222;
    margin-right: 0.5rem;
    border: none;
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.75rem;
      color: var(--clr-white);
    }
  }
  .active {
    opacity: 1;
  }
  .btn-container {
    margin-top: 2rem;
  }

  .btn {
    margin-top: 1rem;
  }
`;
export default AddToCart;
