import styled from 'styled-components';
import logoShop from '../assets/logo-shop.svg';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { links, social } from '../utils/constants';
import CartSignInOutButtons from './CartSignInOutButtons';
import { useProductsContext } from '../context/products_context';
import { useTranslation } from 'react-i18next';

const Nav = () => {
  const { t } = useTranslation();

  const { openSidebar } = useProductsContext();

  return (
    <NavContainer>
      <div className='nav-center'>
        <div className='logo-container'>
          <Link to='/'>
            <img src={logoShop} alt={t('nav_about_label')} />
          </Link>
          <ul className='social-icons'>
            {social.map((link) => {
              const { id, url, icon } = link;
              return (
                <li key={id}>
                  <a href={url} target='_blank' rel='noopener noreferrer'>
                    {icon}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <ul className='nav-links'>
          {links.map((link) => {
            const { id, text, url } = link;
            return (
              <li key={id}>
                <Link to={url}>{t(text)}</Link>
              </li>
            );
          })}
        </ul>
        <CartSignInOutButtons />
        <div className='nav-header'>
          <button type='button' className='nav-toggle' onClick={openSidebar}>
            <FaBars />
          </button>
        </div>
      </div>
    </NavContainer>
  );
};

const NavContainer = styled.nav`
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  .text-slanted {
    font-family: 'Kaushan Script', cursive;
    color: var(--clr-primary-1);
  }
  .text-logo {
    font-family: Arial, sans-serif;
    font-size: 0.5rem;
    color: var(--clr-primary-1);
  }
  .nav-center {
    display: flex;
    justify-content: space-between;
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
  }
  .nav-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 200px;
      margin-top: 3rem;
      margin-left: -15px;
    }
  }
  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav-toggle {
    background: transparent;
    border: transparent;
    color: var(--clr-primary-5);
    cursor: pointer;
    svg {
      font-size: 2rem;
    }
  }
  .nav-links {
    display: none;
  }
  .cart-btn-wrapper {
    display: none;
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 200px;
      margin-top: 3rem;
      margin-left: -15px;
    }
  }
  @media (min-width: 996px) {
    margin-bottom: 0;
    .nav-toggle {
      display: none;
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
    }
    .nav-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 200px;
        margin-top: 3rem;
        margin-left: -15px;
      }
    }
    .nav-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .nav-links {
      display: flex;
      justify-content: center;
      li {
        margin: 0 0.5rem;
      }
      a {
        color: var(--clr-grey-3);
        font-size: 1.5rem;
        text-transform: capitalize;
        letter-spacing: var(--spacing);
        padding: 0.5rem;
        &:hover {
          border-bottom: 2px solid var(--clr-primary-7);
        }
      }
    }
    .cart-btn-wrapper {
      display: grid;
    }
  }
`;

export default Nav;
