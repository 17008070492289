import {
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE,
  SET_LANGAGE,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_SINGLE_PRODUCT_BEGIN,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_ERROR,
} from '../actions';

const products_reducer = (state, action) => {
  switch (action.type) {
    case SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpen: true,
      };

    case SIDEBAR_CLOSE:
      return {
        ...state,
        isSidebarOpen: false,
      };

    case GET_PRODUCTS_BEGIN:
      return { ...state, products_loading: true };

    case SET_LANGAGE:
      return { ...state, langage: action.payload };

    case GET_PRODUCTS_SUCCESS:
      const sorted_products = action.payload.sort(
        (a, b) => b.original_creation_timestamp - a.original_creation_timestamp
      );
      const featured_products = sorted_products.slice(0, 3);

      return {
        ...state,
        products_loading: false,
        products: sorted_products,
        featured_products,
      };

    case GET_PRODUCTS_ERROR:
      return {
        ...state,
        products_loading: false,
        products_error: true,
        error_message: action.payload,
      };

    case GET_SINGLE_PRODUCT_BEGIN:
      return {
        ...state,
        single_product_loading: true,
        single_product_error: false,
      };

    case GET_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        single_product_loading: false,
        single_product: action.payload,
      };

    case GET_SINGLE_PRODUCT_ERROR:
      return {
        ...state,
        single_product_loading: false,
        single_product_error: true,
        error_message: action.payload,
      };

    default:
      throw new Error(`No Matching "${action.type}" - action type`);
  }
};

export default products_reducer;
