const Error = ({ message }) => {
  return (
    <div className='section section-center text-center'>
      <h2 style={{ textTransform: 'none' }}>
        Oops ! une erreur est survenue...
      </h2>
      <h3>{message}</h3>
    </div>
  );
};

export default Error;
