import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useCartContext } from '../context/cart_context';
import styled from 'styled-components';
import { Loading, BreadCrumb, StripeCheckout } from '../components';

const CheckoutPage = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const { cart } = useCartContext();

  useEffect(() => {
    if (!isAuthenticated || cart.length < 1) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate('/');
    } else {
      navigate('/checkout', { replace: true });
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  if (isAuthenticated && cart.length > 0) {
    return (
      <main className='hero-section'>
        <BreadCrumb breadcrumb={t('checkout_page.title')} />
        <CheckoutPageStyle className='page-checkout'>
          <StripeCheckout />
        </CheckoutPageStyle>
      </main>
    );
  } else {
    return <Loading />;
  }
};
const CheckoutPageStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default CheckoutPage;
