import he from 'he';
import { sections } from '../data/shop_sections';
import { shippingProfile } from '../data/shipping_profile';
import {
  ADD_TO_CART,
  CLEAR_CART,
  COUNT_CART_TOTALS,
  REMOVE_CART_ITEM,
  SET_DOMESTIC_SHIPPING,
  SET_INTERNATIONAL_SHIPPING,
  SET_STRIPE_PAYMENT,
  SET_PAYPAL_PAYMENT,
} from '../actions';

const cart_reducer = (state, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      let itemTitle = '';
      let itemCategory = '';
      const { id, quantity, product, language } = action.payload;
      const productSection = sections.find(
        (section) => section.shop_section_id === product.shop_section_id
      );

      const { us, fr } = product.translations;
      if (language === 'en' && us !== undefined) {
        itemTitle = us.title;
        itemCategory = productSection.title_en;
      } else if (language === 'fr' && fr !== undefined) {
        itemTitle = fr.title;
        itemCategory = productSection.title_fr;
      }

      // ETSY API V3 "shipping_profile_id": 189914356000 (type number)
      const productShippingProfile = shippingProfile.find(
        (profil) => profil.shipping_profile_id === product.shipping_profile_id
      );
      const shippingProfileDestination =
        productShippingProfile.shipping_profile_destinations;

      const localShippingProfile = shippingProfileDestination.find(
        (dest) => dest.destination_region === 'France'
      );
      const intlShippingProfile = shippingProfileDestination.find(
        (dest) => dest.destination_region === 'Worldwide'
      );
      const {
        primary_cost: local_primary_cost,
        secondary_cost: local_secondary_cost,
        // eslint-disable-next-line no-unused-vars
        destination_region: local_destination,
      } = localShippingProfile;

      const {
        primary_cost: intl_primary_cost,
        secondary_cost: intl_secondary_cost,
        // eslint-disable-next-line no-unused-vars
        destination_region: intl_destination,
      } = intlShippingProfile;

      let local_shipping_fee_base =
        local_primary_cost.amount / local_primary_cost.divisor;
      let local_shipping_fee_add =
        local_secondary_cost.amount / local_secondary_cost.divisor;
      let intl_shipping_fee_base =
        intl_primary_cost.amount / intl_primary_cost.divisor;
      let intl_shipping_fee_add =
        intl_secondary_cost.amount / intl_secondary_cost.divisor;

      const tempItem = state.cart.find((i) => i.id === id);
      if (tempItem) {
        return { ...state };
      } else {
        const newItem = {
          id: id,
          name: he.decode(itemTitle.substring(0, 28)),
          category: he.decode(itemCategory),
          quantity,
          image: product.images[0].url_570xN,
          price: product.price.amount / product.price.divisor,
          shipping_fee: {
            local_shipping_fee_add: parseFloat(local_shipping_fee_add),
            local_shipping_fee_base: parseFloat(local_shipping_fee_base),
            intl_shipping_fee_add: parseFloat(intl_shipping_fee_add),
            intl_shipping_fee_base: parseFloat(intl_shipping_fee_base),
          },
          max: product.quantity,
        };
        return { ...state, cart: [...state.cart, newItem] };
      }

    case REMOVE_CART_ITEM:
      const tempCart = state.cart.filter((item) => item.id !== action.payload);
      return { ...state, cart: tempCart };

    case CLEAR_CART:
      return { ...state, cart: [] };

    case SET_DOMESTIC_SHIPPING:
      return {
        ...state,
        isLocalShipping: 'yes',
      };

    case SET_INTERNATIONAL_SHIPPING:
      return {
        ...state,
        isLocalShipping: 'no',
      };

    case SET_STRIPE_PAYMENT:
      return {
        ...state,
        isStripePayment: 'yes',
      };

    case SET_PAYPAL_PAYMENT:
      return {
        ...state,
        isStripePayment: 'no',
      };

    case COUNT_CART_TOTALS:
      // flag to handle first shipping base fee just on time
      let isFirstShippingFee = state.cart.length > 0 || false;
      const { isLocalShipping } = state;
      const { total_items, total_amount, total_shipping_fee } =
        state.cart.reduce(
          (total, cartItem) => {
            const { quantity, price, shipping_fee } = cartItem;
            total.total_items += quantity;
            total.total_amount += price * quantity;
            if (isFirstShippingFee) {
              isLocalShipping === 'yes'
                ? (total.total_shipping_fee +=
                    shipping_fee.local_shipping_fee_base)
                : (total.total_shipping_fee +=
                    shipping_fee.intl_shipping_fee_base);
            } else {
              isLocalShipping === 'yes'
                ? (total.total_shipping_fee +=
                    shipping_fee.local_shipping_fee_add)
                : (total.total_shipping_fee +=
                    shipping_fee.intl_shipping_fee_add);
            }
            isFirstShippingFee = false;

            return total;
          },
          {
            total_items: 0,
            total_amount: 0,
            total_shipping_fee: 0,
          }
        );

      return { ...state, total_items, total_amount, total_shipping_fee };

    default:
      throw new Error(`No Matching "${action.type}" - action type`);
  }
};

export default cart_reducer;
