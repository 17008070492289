import styled from 'styled-components';

const MastercardLogo = () => {
  return (
    <MastercardLogoStyle>
      <svg
        viewBox='0 0 131.39 86.9'
        aria-labelledby='paymentsmastercard-2-mastercard'
        role='img'
        focusable='false'
      >
        <title id='paymentsmastercard-2-mastercard'>Mastercard</title>
        <g className='a'>
          <rect className='b'></rect>
        </g>
        <rect className='c' x='48.37' y='15.14'></rect>
        <path
          className='d'
          d='M51.94,43.45a35.94,35.94,0,0,1,13.75-28.3,36,36,0,1,0,0,56.61A35.94,35.94,0,0,1,51.94,43.45Z'
        ></path>
        <path
          className='e'
          d='M120.5,65.76V64.6H121v-.24h-1.19v.24h.47v1.16Zm2.31,0v-1.4h-.36l-.42,1-.42-1h-.36v1.4h.26V64.7l.39.91h.27l.39-.91v1.06Z'
        ></path>
        <path
          className='e'
          d='M123.94,43.45a36,36,0,0,1-58.25,28.3,36,36,0,0,0,0-56.61,36,36,0,0,1,58.25,28.3Z'
        ></path>
      </svg>
    </MastercardLogoStyle>
  );
};

const MastercardLogoStyle = styled.svg`
  width: 100%;
  height: 100%;
  .a {
    opacity: 0;
  }
  .b {
    width: 131.39px;
    height: 86.9px;
    fill: #fff;
  }
  .c {
    width: 34.66px;
    height: 56.61px;
    fill: #ff5f00;
  }
  .d {
    fill: #eb001b;
  }
  .e {
    fill: #f79e1b;
  }
`;

export default MastercardLogo;
