import { useCallback, useRef } from 'react';
import he from 'he';
import styled from 'styled-components';
import { FaSearch, FaShoppingCart } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import LightGallery from 'lightgallery/react';

// import LightGallery plugins
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

const PhotoGallery = ({ listing_id, images, title }) => {
  const mainImage = images[0].url_fullxfull;
  const listPhotos = images.map((img) => {
    return {
      id: img.listing_image_id,
      size: '2000-2000',
      src: img.url_fullxfull,
      thumb: img.url_570xN,
      subHtml: `<div class="lightGallery-captions">
        <h3> ${title.substring(0, 28)}</h3>
        <span class="credits"> &copy; ${new Date().getFullYear()} crédits photos Rar&Sens</span>
          </div>`,
    };
  });

  // declare lightGallery object reference & callback
  const lightGallery = useRef(null);

  const openGallery = useCallback(() => {
    lightGallery.current.openGallery();
  }, []);

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  return (
    <PhotoGalleryStyle>
      <article className="photo">
        <img src={mainImage} alt={he.decode(title.substring(0, 35))} />
        <LightGallery
          elementClassNames="custom-classname"
          dynamic
          dynamicEl={listPhotos}
          onInit={onInit}
          plugins={[lgThumbnail, lgZoom, lgVideo]}
          licenseKey="2AA8CBC9-552F437C-85067FAE-94662C42"
        ></LightGallery>
        <div className="photo-info">
          <div className="link-container">
            <span className="link-gallery" onClick={openGallery}>
              <FaSearch />
            </span>
            <Link to={`/products/${listing_id}`} className="link-shop">
              <FaShoppingCart />
            </Link>
          </div>
        </div>
      </article>
    </PhotoGalleryStyle>
  );
};

const PhotoGalleryStyle = styled.div`
  .photo {
    height: 17.5rem;
    position: relative;
    overflow: hidden;
  }
  .photo > img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  .photo-info {
    position: absolute;
    width: 100%;
    height: 88px;
    padding: 1rem;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    transform: translateY(100%);
    transition: var(--transition);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .photo:hover .photo-info {
    transform: translateY(0);
  }
  .link-container {
    display: flex;
    align-items: center;
    img {
      width: 200px;
      margin-top: 3rem;
      margin-left: -15px;
    }
  }
  .link-gallery {
    position: absolute;
    top: 50%;
    left: 33%;
    transform: translate(-33%, -50%);
    background: var(--clr-primary-5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: var(--transition);
    opacity: 1;
    cursor: pointer;
    svg {
      font-size: 1.25rem;
      color: var(--clr-white);
    }
  }
  .link-shop {
    position: absolute;
    top: 50%;
    left: 66%;
    transform: translate(-66%, -50%);
    background: var(--clr-primary-5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: var(--transition);
    opacity: 1;
    cursor: pointer;
    svg {
      font-size: 1.25rem;
      color: var(--clr-white);
    }
  }
`;

export default PhotoGallery;
