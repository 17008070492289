import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import he from 'he';

const FeaturedProduct = ({ product, langage }) => {
  const { listing_id, images, translations } = product;
  const mainImage = images[0].url_570xN;
  let lnTitle = '';

  const { us, fr } = translations;

  if (langage === 'en') {
    lnTitle = us.title;
  } else {
    lnTitle = fr.title;
  }

  return (
    <FeaturedProductStyle>
      <div className="container">
        <img src={mainImage} alt={he.decode(lnTitle.substring(0, 35))} />
        <Link to={`/products/${listing_id}`} className="link">
          <FaSearch />
        </Link>
      </div>
      <footer>
        <h5 className="text-multiline">{he.decode(lnTitle)}</h5>
      </footer>
    </FeaturedProductStyle>
  );
};

const FeaturedProductStyle = styled.article`
  .container {
    position: relative;
    background: var(--clr-black);
    border-radius: var(--radius);
  }
  img {
    width: 100%;
    display: block;
    object-fit: cover;
    border-radius: var(--radius);
    transition: var(--transition);
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--clr-primary-5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: var(--transition);
    opacity: 0;
    cursor: pointer;
    svg {
      font-size: 1.25rem;
      color: var(--clr-white);
    }
  }
  .container:hover img {
    opacity: 0.5;
  }
  .container:hover .link {
    opacity: 1;
  }
  footer {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  footer h5,
  footer p {
    margin-bottom: 0;
    font-weight: 400;
  }
  footer p {
    color: var(--clr-primary-5);
    letter-spacing: var(--spacing);
  }
  footer h5 {
    font-size: 1rem;
    text-transform: none;
    flex-wrap: wrap;
  }
  .text-multiline {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
`;
export default FeaturedProduct;
