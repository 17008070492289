const shippingProfile = [
  {
    shipping_profile_id: 175237471337,
    title: 'classique',
    user_id: 19063809,
    min_processing_days: 1,
    max_processing_days: 3,
    processing_days_display_label: '1-3 business days',
    origin_country_iso: 'FR',
    origin_postal_code: '31000',
    profile_type: 'manual',
    is_deleted: false,
    domestic_handling_fee: 0,
    international_handling_fee: 0,
    shipping_profile_destinations: [
      {
        shipping_profile_destination_id: 33149177182,
        shipping_profile_id: 175237471337,
        origin_country_iso: 'FR',
        destination_country_iso: '',
        destination_region: 'none',
        primary_cost: {
          amount: 1050,
          divisor: 100,
          currency_code: 'EUR',
        },
        secondary_cost: {
          amount: 0,
          divisor: 100,
          currency_code: 'EUR',
        },
        shipping_carrier_id: 0,
        mail_class: null,
        min_delivery_days: 15,
        max_delivery_days: 15,
      },
      {
        shipping_profile_destination_id: 33149177174,
        shipping_profile_id: 175237471337,
        origin_country_iso: 'FR',
        destination_country_iso: 'FR',
        destination_region: 'none',
        primary_cost: {
          amount: 280,
          divisor: 100,
          currency_code: 'EUR',
        },
        secondary_cost: {
          amount: 0,
          divisor: 100,
          currency_code: 'EUR',
        },
        shipping_carrier_id: 0,
        mail_class: null,
        min_delivery_days: 3,
        max_delivery_days: 3,
      },
    ],
    shipping_profile_upgrades: [],
  },
  {
    shipping_profile_id: 61776643748,
    title: 'Colissimo',
    user_id: 19063809,
    min_processing_days: 1,
    max_processing_days: 3,
    processing_days_display_label: '1-3 business days',
    origin_country_iso: 'FR',
    origin_postal_code: '',
    profile_type: 'manual',
    is_deleted: false,
    domestic_handling_fee: 0,
    international_handling_fee: 0,
    shipping_profile_destinations: [
      {
        shipping_profile_destination_id: 16545801951,
        shipping_profile_id: 61776643748,
        origin_country_iso: 'FR',
        destination_country_iso: 'FR',
        destination_region: 'none',
        primary_cost: {
          amount: 500,
          divisor: 100,
          currency_code: 'EUR',
        },
        secondary_cost: {
          amount: 0,
          divisor: 100,
          currency_code: 'EUR',
        },
        shipping_carrier_id: 19,
        mail_class: null,
        min_delivery_days: null,
        max_delivery_days: null,
      },
      {
        shipping_profile_destination_id: 16551335332,
        shipping_profile_id: 61776643748,
        origin_country_iso: 'FR',
        destination_country_iso: 'US',
        destination_region: 'none',
        primary_cost: {
          amount: 2400,
          divisor: 100,
          currency_code: 'EUR',
        },
        secondary_cost: {
          amount: 0,
          divisor: 100,
          currency_code: 'EUR',
        },
        shipping_carrier_id: 19,
        mail_class: null,
        min_delivery_days: null,
        max_delivery_days: null,
      },
    ],
    shipping_profile_upgrades: [],
  },
  {
    shipping_profile_id: 28429756633,
    title: 'Gratuit',
    user_id: 19063809,
    min_processing_days: 1,
    max_processing_days: 3,
    processing_days_display_label: '1-3 business days',
    origin_country_iso: 'FR',
    origin_postal_code: '31000',
    profile_type: 'manual',
    is_deleted: false,
    domestic_handling_fee: 0,
    international_handling_fee: 0,
    shipping_profile_destinations: [
      {
        shipping_profile_destination_id: 13195830288,
        shipping_profile_id: 28429756633,
        origin_country_iso: 'FR',
        destination_country_iso: '',
        destination_region: 'none',
        primary_cost: {
          amount: 0,
          divisor: 100,
          currency_code: 'EUR',
        },
        secondary_cost: {
          amount: 0,
          divisor: 100,
          currency_code: 'EUR',
        },
        shipping_carrier_id: 19,
        mail_class: 'international_priority_letter',
        min_delivery_days: null,
        max_delivery_days: null,
      },
      {
        shipping_profile_destination_id: 13190121143,
        shipping_profile_id: 28429756633,
        origin_country_iso: 'FR',
        destination_country_iso: 'FR',
        destination_region: 'none',
        primary_cost: {
          amount: 0,
          divisor: 100,
          currency_code: 'EUR',
        },
        secondary_cost: {
          amount: 0,
          divisor: 100,
          currency_code: 'EUR',
        },
        shipping_carrier_id: 0,
        mail_class: null,
        min_delivery_days: 3,
        max_delivery_days: 3,
      },
    ],
    shipping_profile_upgrades: [],
  },
  {
    shipping_profile_id: 189914356000,
    title: 'l\u00e9ger',
    user_id: 19063809,
    min_processing_days: 1,
    max_processing_days: 3,
    processing_days_display_label: '1-3 business days',
    origin_country_iso: 'FR',
    origin_postal_code: '31000',
    profile_type: 'manual',
    is_deleted: false,
    domestic_handling_fee: 0,
    international_handling_fee: 0,
    shipping_profile_destinations: [
      {
        shipping_profile_destination_id: 38029352174,
        shipping_profile_id: 189914356000,
        origin_country_iso: 'FR',
        destination_country_iso: '',
        destination_region: 'Worldwide',
        primary_cost: {
          amount: 630,
          divisor: 100,
          currency_code: 'EUR',
        },
        secondary_cost: {
          amount: 100,
          divisor: 100,
          currency_code: 'EUR',
        },
        shipping_carrier_id: 0,
        mail_class: null,
        min_delivery_days: 30,
        max_delivery_days: 30,
      },
      {
        shipping_profile_destination_id: 37999472671,
        shipping_profile_id: 189914356000,
        origin_country_iso: 'FR',
        destination_country_iso: 'FR',
        destination_region: 'France',
        primary_cost: {
          amount: 280,
          divisor: 100,
          currency_code: 'EUR',
        },
        secondary_cost: {
          amount: 60,
          divisor: 100,
          currency_code: 'EUR',
        },
        shipping_carrier_id: 0,
        mail_class: null,
        min_delivery_days: 3,
        max_delivery_days: 3,
      },
    ],
    shipping_profile_upgrades: [],
  },
];
module.exports = { shippingProfile };
