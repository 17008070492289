import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const CartColumns = () => {
  const { t } = useTranslation();

  return (
    <CartColumnsStyle>
      <div className='content'>
        <h5>{t('cart_page.cart_header.col1')}</h5>
        <h5>{t('cart_page.cart_header.col2')}</h5>
        <h5>{t('cart_page.cart_header.col3')}</h5>
        <h5>{t('cart_page.cart_header.col4')}</h5>
        <span></span>
      </div>
      <hr />
    </CartColumnsStyle>
  );
};

const CartColumnsStyle = styled.div`
  display: none;
  @media (min-width: 776px) {
    display: block;
    .content {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr auto;
      justify-items: center;
      column-gap: 1rem;
      h5 {
        color: var(--clr-grey-5);
        font-weight: 400;
      }
    }

    span {
      width: 2rem;
      height: 2rem;
    }
    hr {
      margin-top: 1rem;
      margin-bottom: 3rem;
    }
  }
`;

export default CartColumns;
