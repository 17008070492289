import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCartContext } from '../context/cart_context';
import CartColumns from './CartColumns';
import CartItem from './CartItem';
import CartTotals from './CartTotals';

const CartContent = () => {
  const { t } = useTranslation();
  const { cart, clearCart } = useCartContext();
  return (
    <CartContentStyle className='section section-center'>
      <CartColumns />
      {cart.map((item) => {
        return <CartItem key={item.id} {...item} />;
      })}
      <hr />
      <div className='link-container'>
        <Link to='/products' className='link-btn'>
          {t('cart_page.content_section.shop_link')}
        </Link>
        <button
          type='button'
          className='link-btn clear-btn'
          onClick={clearCart}
        >
          {t('cart_page.content_section.clear_cart')}
        </button>
      </div>
      <CartTotals />
    </CartContentStyle>
  );
};
const CartContentStyle = styled.section`
  .link-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .link-btn {
    background: transparent;
    border-color: transparent;
    text-transform: none;
    padding: 0.25rem 0.5rem;
    margin-left: 3rem;
    background: var(--clr-primary-5);
    color: var(--clr-white);
    border-radius: var(--radius);
    font-weight: 400;
    cursor: pointer;
  }
  .clear-btn {
    background: var(--clr-black);
    font-size: 0.875rem;
    margin-right: 3rem;
  }

  @media (min-width: 800px) {
    .clear-btn {
      background: var(--clr-black);
      font-size: 1rem;
    }
  }
`;
export default CartContent;
