import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFilterContext } from '../context/filter_context';
import { getUniqueValues, formatPrice } from '../utils/helpers';
import { sections } from '../data/shop_sections';

const Filters = () => {
  const { i18n, t } = useTranslation();
  const {
    filters: {
      searchText,
      category,
      style,
      min_price,
      max_price,
      price,
      promotion,
    },
    updateFilters,
    clearFilters,
    all_products,
  } = useFilterContext();

  const categories = getUniqueValues(
    t('products_page.filters.filter_prefix'),
    sections,
    `title_${i18n.resolvedLanguage}`
  );
  const styles = getUniqueValues(
    t('products_page.filters.filter_prefix'),
    all_products,
    'style'
  );

  return (
    <FiltersStyle>
      <div className="content">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* search input */}
          <div className="form-control">
            <input
              type="text"
              name="searchText"
              placeholder={t('products_page.filters.search_placeholder')}
              className="search-input"
              value={searchText}
              onChange={updateFilters}
            />
          </div>
          {/* end search input */}
          {/* categories */}
          <div className="form-control">
            <h5>{t('products_page.filters.category.label')}</h5>
            <div>
              {categories.map((c, index) => {
                return (
                  <button
                    key={index}
                    onClick={updateFilters}
                    type="button"
                    name="category"
                    className={`${category === c ? 'active' : null}`}
                  >
                    {c}
                  </button>
                );
              })}
            </div>
          </div>
          {/* end of categories */}
          {/* style */}
          <div className="form-control">
            <h5>{t('products_page.filters.style.label')}</h5>
            <select
              name="style"
              value={style}
              onChange={updateFilters}
              className="style"
            >
              {styles.map((c, index) => {
                return (
                  <option key={index} value={c}>
                    {c}
                  </option>
                );
              })}
            </select>
          </div>
          {/* end of style */}
          {/* price */}
          <div className="form-control">
            <h5>{t('products_page.filters.price.label')}</h5>
            <p className="price">{formatPrice(price)}</p>
            <input
              type="range"
              name="price"
              min={min_price}
              max={max_price}
              onChange={updateFilters}
              value={price}
            />
          </div>
          {/* end of price */}
          {/* reserved */}
          <div className="form-control promotion">
            <label htmlFor="promotion">
              {t('products_page.filters.promotion.label')}
            </label>
            <input
              type="checkbox"
              name="promotion"
              id="promotion"
              onChange={updateFilters}
              checked={promotion}
            />
          </div>
          {/* end of  reserved */}
        </form>
        <button type="button" className="clear-btn" onClick={clearFilters}>
          {t('products_page.filters.clear_btn')}
        </button>
      </div>
    </FiltersStyle>
  );
};

const FiltersStyle = styled.section`
  .form-control {
    margin-bottom: 1.25rem;
    h5 {
      margin-bottom: 0.5rem;
    }
  }
  .search-input {
    padding: 0.5rem;
    background: var(--clr-grey-10);
    border-radius: var(--radius);
    border-color: transparent;
    letter-spacing: var(--spacing);
  }
  .search-input::placeholder {
    text-transform: capitalize;
  }

  button {
    display: block;
    margin: 0.25em 0;
    padding: 0.25rem 0;
    text-transform: none;
    background: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    letter-spacing: var(--spacing);
    color: var(--clr-grey-5);
    cursor: pointer;
  }
  .active {
    border-color: var(--clr-grey-5);
  }
  .style {
    background: var(--clr-grey-10);
    border-radius: var(--radius);
    border-color: transparent;
    padding: 0.25rem;
  }
  .colors {
    display: flex;
    align-items: center;
  }
  .color-btn {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #222;
    margin-right: 0.5rem;
    border: none;
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.5rem;
      color: var(--clr-white);
    }
  }
  .all-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    opacity: 0.5;
  }
  .active {
    opacity: 1;
  }
  .all-btn .active {
    text-decoration: underline;
  }
  .price {
    margin-bottom: 0.25rem;
  }
  .promotion {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    text-transform: capitalize;
    column-gap: 0.5rem;
    font-size: 1rem;
  }
  .clear-btn {
    background: var(--clr-red-dark);
    color: var(--clr-white);
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
  }
  @media (min-width: 768px) {
    .content {
      position: sticky;
      top: 1rem;
    }
  }
`;

export default Filters;
