import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import '../../node_modules/flag-icon-css/css/flag-icons.min.css';

import { languageList } from '../utils/constants';

const getLanguage = (selected) =>
  Object.keys(languageList).filter(
    (lang) => languageList[lang].nativeName === selected
  )[0];

const TranslateButtons = () => {
  const { i18n } = useTranslation();
  return (
    <TranslateButtonsStyle>
      <select
        style={{
          fontWeight: 'bold',
        }}
        value={languageList[i18n.resolvedLanguage].nativeName}
        onChange={(e) => i18n.changeLanguage(getLanguage(e.target.value))}
      >
        {Object.keys(languageList).map((lng) => (
          <option key={lng}>{languageList[lng].nativeName}</option>
        ))}
      </select>
      <span
        className={`flag-icon flag-icon-${
          i18n.resolvedLanguage === 'en' ? 'us' : i18n.resolvedLanguage
        } mr-1`}
      ></span>
    </TranslateButtonsStyle>
  );
};

const TranslateButtonsStyle = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;

  span {
    width: 0.95em;
    margin-left: 0.25rem;
  }

  .cart-btn {
    color: var(--clr-grey-1);
    font-size: 1.5rem;
    letter-spacing: var(--spacing);
    color: var(--clr-grey-1);
    display: flex;

    align-items: center;
  }
  .cart-container {
    display: flex;
    align-items: center;
    position: relative;
    svg {
      height: 1.6rem;
      margin-left: 5px;
    }
  }
  .cart-value {
    position: absolute;
    top: -10px;
    right: -16px;
    background: var(--clr-primary-5);
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 0.75rem;
    color: var(--clr-white);
    padding: 12px;
  }
  .auth-btn {
    display: flex;
    align-items: center;
    background: transparent;
    border-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--clr-grey-1);
    letter-spacing: var(--spacing);
    svg {
      margin-left: 5px;
    }
  }
`;
export default TranslateButtons;
