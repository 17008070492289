const sections = [
  {
    shop_section_id: '10973415',
    title_fr: 'Bagues',
    title_en: 'Rings',
    rank: 2,
    user_id: 19063809,
    active_listing_count: 0,
  },
  {
    shop_section_id: '10973417',
    title_fr: "Boucles d'oreille",
    title_en: 'Earrings',
    rank: 3,
    user_id: 19063809,
    active_listing_count: 9,
  },
  {
    shop_section_id: '10973419',
    title_fr: 'Bracelets',
    title_en: 'Bracelets',
    rank: 4,
    user_id: 19063809,
    active_listing_count: 2,
  },
  {
    shop_section_id: '10978496',
    title_fr: 'Colliers',
    title_en: 'Necklaces',
    rank: 1,
    user_id: 19063809,
    active_listing_count: 11,
  },

  {
    shop_section_id: '36935702',
    title_fr: 'Broches',
    title_en: 'Broches',
    rank: 6,
    user_id: 19063809,
    active_listing_count: 1,
  },
  // {
  //   shop_section_id: '12494465',
  //   title: 'Other stuff',
  //   rank: 5,
  //   user_id: 19063809,
  //   active_listing_count: 0,
  // },
];
module.exports = { sections };
