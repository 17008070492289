import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { links, social } from '../utils/constants';
import logoShop from '../assets/logo-shop.svg';
import CartSignInOutButtons from './CartSignInOutButtons';
import { useProductsContext } from '../context/products_context';

const Sidebar = () => {
  const { t } = useTranslation();
  const { isSidebarOpen, closeSidebar } = useProductsContext();

  return (
    <SidebarStyle>
      <aside
        className={`${isSidebarOpen ? 'sidebar show-sidebar' : 'sidebar'}`}
      >
        <div className="sidebar-header">
          <div className="logo-container">
            <Link to="/">
              <img src={logoShop} alt={t('nav_about_label')} />
            </Link>
            <ul className="social-icons">
              {social.map((link) => {
                const { id, url, icon } = link;
                return (
                  <li key={id}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {icon}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <button className="close-btn" type="button" onClick={closeSidebar}>
            <FaTimes />
          </button>
        </div>
        <ul className="links">
          {links.map(({ id, text, url }) => {
            return (
              <li key={id}>
                <Link to={url} onClick={closeSidebar}>
                  {t(text)}
                </Link>
              </li>
            );
          })}
        </ul>
        <CartSignInOutButtons />
      </aside>
    </SidebarStyle>
  );
};

const SidebarStyle = styled.div`
  text-align: center;
  .text-slanted {
    font-family: 'Kaushan Script', cursive;
    color: var(--clr-primary-1);
  }
  .text-logo {
    font-family: Arial, sans-serif;
    font-size: 2rem;
    color: var(--clr-primary-1);
  }
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 3rem;
  }
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 200px;
      margin-left: -15px;
    }
  }
  .close-btn {
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: var(--clr-primary-5);
    transition: var(--transition);
    cursor: pointer;
    color: var(--clr-red-dark);
    margin-top: 0.2rem;
  }
  .close-btn:hover {
    color: var(--clr-red-light);
  }
  .logo {
    justify-self: center;
    height: 45px;
  }
  .links {
    margin-bottom: 2rem;
  }
  .links a {
    display: block;
    text-align: left;
    font-size: 1.25rem;
    text-transform: capitalize;
    padding: 1rem 1.5rem;
    color: var(--clr-grey-3);
    transition: var(--transition);
    letter-spacing: var(--spacing);
  }

  .links a:hover {
    padding: 1rem 1.5rem;
    padding-left: 2rem;
    background: var(--clr-grey-10);
    color: var(--clr-grey-2);
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--clr-white);
    // display: grid;
    // grid-template-rows: auto 1fr auto;
    // row-gap: 1rem;
    transition: var(--transition);
    transform: translate(-100%);
    z-index: -1;
  }
  .show-sidebar {
    transform: translate(0);
    z-index: 999;
  }
  .cart-btn-wrapper {
    margin: 2rem auto;
  }
  @media screen and (min-width: 992px) {
    .sidebar {
      display: none;
    }
  }
`;

export default Sidebar;
