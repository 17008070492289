import { useState } from 'react';
import styled from 'styled-components';

const ProductImages = ({ images }) => {
  const [main, setMain] = useState(images[0]);

  if (main.listing_id !== images[0].listing_id) setMain(images[0]);
  // if (images !== undefined) {

  return (
    <ProductImagesStyle>
      <img src={main.url_570xN} alt="main" className="main" />
      <div className="gallery">
        {images.map((image, index) => {
          return (
            <img
              src={image.url_570xN}
              alt={image.listing_image_id}
              key={index}
              onClick={() => {
                return setMain(images[index]);
              }}
              className={`${
                image.url_570xN === main.url_570xN ? 'active' : null
              }`}
            />
          );
        })}
      </div>
    </ProductImagesStyle>
  );
  // }
};

const ProductImagesStyle = styled.section`
  .main {
    height: 600px;
  }
  img {
    width: 100%;
    display: block;
    border-radius: var(--radius);
    object-fit: cover;
  }
  .gallery {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 1rem;
    row-gap: 0.5rem;
    img {
      height: 100px;
      cursor: pointer;
    }
  }
  .active {
    box-shadow: 0px 0px 0px 3px var(--clr-black);
  }
  @media (max-width: 576px) {
    .main {
      height: 300px;
    }
    .gallery {
      img {
        height: 50px;
      }
    }
  }
  @media (min-width: 992px) {
    .main {
      height: 500px;
    }
    .gallery {
      img {
        height: 75px;
      }
    }
  }
`;

export default ProductImages;
