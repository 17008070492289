import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BreadCrumb = ({ breadcrumb, product }) => {
  const { t } = useTranslation();

  return (
    <BreadCrumbStyle>
      <div className='section-center'>
        <h3>
          <Link to='/'>{t('nav_home_label')}</Link>
          {product === 'products' && (
            <Link to='/products'>/ {t('nav_shop_label')}</Link>
          )}
          {product === 'gallery' && (
            <Link to='/gallery'>/ {t('nav_gallery_label')}</Link>
          )}
          {breadcrumb}
        </h3>
      </div>
    </BreadCrumbStyle>
  );
};

const BreadCrumbStyle = styled.section`
  background: var(--clr-primary-10);
  width: 100%;
  min-height: 15vh;
  display: flex;
  align-items: center;
  margin-top: 0px;

  color: var(--clr-primary-1);
  a {
    color: var(--clr-primary-3);
    padding: 0.5rem;
    transition: var(--transition);
  }
  a:hover {
    color: var(--clr-primary-1);
  }

  h3 {
    text-transform: none;
  }
`;

export default BreadCrumb;
