import { useFilterContext } from '../context/filter_context';
import { useTranslation } from 'react-i18next';

import GridView from './GridView';
import ListView from './ListView';

const ProductList = () => {
  const { t } = useTranslation();

  const { filtered_products: products, grid_view } = useFilterContext();

  if (products.length < 1) {
    return (
      <h5 style={{ textTransform: 'none' }}>
        {t('products_page.sort.not_found_label')}
      </h5>
    );
  }
  if (grid_view === false) {
    return <ListView products={products} />;
  }

  return <GridView products={products}>product list</GridView>;
};

export default ProductList;
