import styled from 'styled-components';

const VisaLogo = () => {
  return (
    <VisaLogoStyle>
      <svg
        viewBox='0 0 54 18'
        aria-labelledby='paymentsvisa-2-visa'
        role='img'
        focusable='false'
      >
        <g className='a'>
          <path
            className='b'
            d='M20.4888889,0.322218392 L13.4222222,17.7675946 L8.8,17.7675946 L5.33333333,3.82049964 C5.11111111,2.94592933 4.93333333,2.66974923 4.31111111,2.3015091 C2.94183443,1.61896514 1.49263492,1.1238228 0,0.828548573 L0.0888888889,0.322218392 L7.51111111,0.322218392 C8.51437428,0.32690813 9.36403311,1.08954942 9.51111111,2.11738903 L11.3333333,12.1979626 L15.8666667,0.322218392 L20.4888889,0.322218392 Z M38.5777778,12.0598726 C38.5777778,7.45687094 32.4444444,7.18069084 32.4888889,5.15537012 C32.4888889,4.51094989 33.0666667,3.86652966 34.3555556,3.68240959 C35.8342967,3.5394088 37.3230136,3.80962598 38.6666667,4.46491987 L39.4222222,0.782518556 C38.1172974,0.263564533 36.7311549,-0.00170820448 35.3333333,8.27692349e-06 C31.0222222,8.27692349e-06 28,2.39356913 27.9555556,5.75376033 C27.9111111,8.28541123 30.1333333,9.66631173 31.7777778,10.494852 C33.4222222,11.3233923 34.0444444,11.9217825 34.0444444,12.6582628 C34.0444444,13.8090132 32.6666667,14.3613734 31.4222222,14.3613734 C29.8583806,14.4061465 28.3105619,14.0252266 26.9333333,13.256653 L26.1333333,17.0771444 C27.6723314,17.7168939 29.3197317,18.029953 30.9777778,17.9977447 C35.5555556,18.0437747 38.5333333,15.6962439 38.5777778,12.0598726 L38.5777778,12.0598726 Z M49.9555556,17.7675946 L54,17.7675946 L50.4888889,0.322218392 L46.7555556,0.322218392 C45.9362429,0.312052784 45.1949487,0.823879561 44.8888889,1.61105885 L38.3111111,17.7675946 L42.8888889,17.7675946 L43.7777778,15.1438837 L49.3777778,15.1438837 L49.9555556,17.7675946 Z M45.0666667,11.5995724 L47.3777778,5.06331008 L48.7111111,11.5995724 L45.0666667,11.5995724 Z M26.7111111,0.322218392 L23.1111111,17.7675946 L18.7555556,17.7675946 L22.3555556,0.322218392 L26.7111111,0.322218392 Z'
          ></path>
        </g>
        <title id='paymentsvisa-2-visa'>Visa</title>
      </svg>
    </VisaLogoStyle>
  );
};

const VisaLogoStyle = styled.svg`
  width: 100%;
  height: 100%;
  .a {
    stroke: none;
    stroke-width: 1px;
    fill: none;
    fill-rule: evenodd;
  }
  .b {
    fill: #1a1f71;
    fill-rule: nonzero;
  }
`;

export default VisaLogo;
