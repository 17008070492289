import he from 'he';
import { sections } from '../data/shop_sections';

import {
  LOAD_PRODUCTS,
  SET_LISTVIEW,
  SET_GRIDVIEW,
  UPDATE_SORT_BY,
  SORT_PRODUCTS,
  UPDATE_FILTERS,
  FILTER_PRODUCTS,
  CLEAR_FILTERS,
} from '../actions';

const filter_reducer = (state, action) => {
  let tempProducts = [];
  switch (action.type) {
    case LOAD_PRODUCTS:
      let productPrices = action.payload.map(
        (p) => p.price.amount / p.price.divisor
      );
      let maxPrice = Math.max(...productPrices);
      let minPrice = Math.min(...productPrices);
      return {
        ...state,
        all_products: [...action.payload],
        filtered_products: [...action.payload],
        filters: {
          ...state.filters,
          min_price: minPrice,
          max_price: maxPrice,
          price: maxPrice,
        },
      };

    case SET_GRIDVIEW:
      return {
        ...state,
        grid_view: true,
      };

    case SET_LISTVIEW:
      return {
        ...state,
        grid_view: false,
      };

    case UPDATE_SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      };

    case SORT_PRODUCTS:
      const { sortBy, filtered_products } = state;
      tempProducts = [...filtered_products];
      if (sortBy === 'date-highest') {
        tempProducts = tempProducts.sort(
          (a, b) =>
            b.original_creation_timestamp - a.original_creation_timestamp
        );
      }
      if (sortBy === 'price-highest') {
        tempProducts = tempProducts.sort(
          (a, b) => b.price.amount - a.price.amount
        );
      }
      if (sortBy === 'price-lowest') {
        tempProducts = tempProducts.sort(
          (a, b) => a.price.amount - b.price.amount
        );
      }
      // example of sorting by alphabetical order (i.e. "a to z" or "z to a")
      // if (sort === 'name-a') {
      //   tempProducts = tempProducts.sort((a, b) => {
      //     return a.name.localeCompare(b.name);
      //   });
      // }
      // if (sort === 'name-z') {
      //   tempProducts = tempProducts.sort((a, b) => {
      //     return b.name.localeCompare(a.name);
      //   });
      // }
      return { ...state, filtered_products: tempProducts };

    // updateFilters(e) input event when updating filter criterias (e=named filter criteria, i.e. search input, category,...)
    case UPDATE_FILTERS:
      const { name, value } = action.payload;
      return { ...state, filters: { ...state.filters, [name]: value } };

    // filtering products based on filter criterias
    case FILTER_PRODUCTS:
      const { all_products } = state;
      const { language } = action.payload;
      const { searchText, category, style, price } = state.filters;
      // First we copy all products to tempProducts
      tempProducts = [...all_products];
      // searchText filter
      if (searchText) {
        tempProducts = tempProducts.filter((product) => {
          const { us, fr } = product.translations;
          if (language === 'en' && us !== undefined) {
            return product.translations.us.title
              .toLowerCase()
              .startsWith(searchText);
            // return he
            //   .decode(product.translations.us.title)
            //   .toLowerCase()
            //   .startsWith(searchText);
          } else if (language === 'fr' && fr !== undefined) {
            return product.translations.fr.title
              .toLowerCase()
              .startsWith(searchText);
            // return he
            //   .decode(product.translations.fr.title)
            //   .toLowerCase()
            //   .startsWith(searchText);
          } else {
            return false;
          }
        });
      }
      // category filter
      if (category !== 'All' && category !== 'Toutes') {
        const section = sections.find(
          (section) =>
            section.title_fr === category || section.title_en === category
        );
        tempProducts = tempProducts.filter(
          (product) => product.shop_section_id === section.shop_section_id
        );
      }

      // style filter
      if (style !== 'All') {
        tempProducts = tempProducts.filter((product) => {
          return product.style.find((s) => s === style);
        });
      }

      // price filter
      tempProducts = tempProducts.filter(
        (product) => product.price.amount / product.price.divisor <= price
      );
      return { ...state, filtered_products: tempProducts };

    case CLEAR_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          searchText: '',
          category: 'All',
          style: 'All',
          tag: 'All',
          material: 'All',
          promotion: false,
          price: state.filters.max_price,
        },
      };

    default:
      throw new Error(`No Matching "${action.type}" - action type`);
  }
};

export default filter_reducer;
