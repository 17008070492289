import axios from 'axios';
import axiosThrottle from 'axios-request-throttle';
import { useTranslation } from 'react-i18next';

import { createContext, useContext, useEffect, useReducer } from 'react';
import reducer from '../reducers/products_reducer';
import { products } from '../data/products';
import {
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE,
  SET_LANGAGE,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_SINGLE_PRODUCT_BEGIN,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_ERROR,
} from '../actions';

const initialState = {
  isSidebarOpen: false,
  products_loading: false,
  products_error: false,
  products: [],
  featured_products: [],
  single_product_loading: false,
  single_product_error: false,
  single_product: {},
  error_message: '',
  langage: 'fr',
};

const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { i18n } = useTranslation();

  //   Throttle axios request due to Etsy API limitations
  axiosThrottle.use(axios, { requestsPerSecond: 5 });

  const openSidebar = () => {
    dispatch({ type: SIDEBAR_OPEN });
  };
  const closeSidebar = () => {
    dispatch({ type: SIDEBAR_CLOSE });
  };

  const getStaticProducts = async (langage) => {
    dispatch({ type: GET_PRODUCTS_BEGIN });
    dispatch({ type: SET_LANGAGE, payload: langage });
    try {
      dispatch({ type: GET_PRODUCTS_SUCCESS, payload: products });
    } catch (error) {
      const error_message = error.message;
      dispatch({ type: GET_PRODUCTS_ERROR, payload: error_message });
    }
  };

  const getStaticSingleProduct = (listing_id) => {
    dispatch({ type: GET_SINGLE_PRODUCT_BEGIN });
    try {
      const findProduct = products.find(
        (p) => p.listing_id === Number(listing_id)
      );

      dispatch({ type: GET_SINGLE_PRODUCT_SUCCESS, payload: findProduct });
    } catch (error) {
      const error_message = error.message;
      dispatch({ type: GET_SINGLE_PRODUCT_ERROR, payload: error_message });
    }
  };

  // refresh UI/components when language changes
  useEffect(() => {
    getStaticProducts(i18n.resolvedLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.resolvedLanguage]);

  return (
    <ProductsContext.Provider
      value={{
        ...state,
        openSidebar,
        closeSidebar,
        getStaticSingleProduct,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
// make sure use
export const useProductsContext = () => {
  return useContext(ProductsContext);
};
